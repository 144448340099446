import { OrganizationAnalyticsSessions } from '../components/Organization/Details';

export function OrganizationAnalyticsSessionsRoute() {
  return <OrganizationAnalyticsSessions />;
}

export const Component = OrganizationAnalyticsSessionsRoute;

export function clientLoader() {
  return null;
}
